import React, { useEffect, useState } from 'react';

const AuthKeyHandler = () => {
	const [message, setMessage] = useState('');
	const [errorCode, setErrorCode] = useState(null);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const authKey = urlParams.get('auth_key');
		const errorCode = urlParams.get('error_code');
		const scope = urlParams.get('scope');
		const success = urlParams.get('success');
		const state = JSON.parse(urlParams.get('state') || '');

		if ( success === 'true' ) {
			setMessage(`This auth key has been successfully stored.  You can now close this page. `);
			return;
		}

		if (errorCode) {
			setErrorCode(errorCode);
			setMessage(`Error encountered. Please send this error code to the administrator: ${errorCode}`);
			return;
		}

		if (authKey) {
			if ( !scope || scope.indexOf('write_invest_order') < 0 ) {
				setErrorCode('MISSING_WRITE');
				setMessage(`Error encountered. Write access was not granted. This can happen if 'Write & Read Access' was not selected on the previous page.`);
				return;
			}


			const recordTimestamp = new Date(state.storeTimestamp);
			const now = new Date();
			const oldestAllowableTime = new Date(now.getTime() - 15 * 60 * 1000);
			const isOld = recordTimestamp < oldestAllowableTime;
			console.log('isOld', isOld, recordTimestamp, oldestAllowableTime);
			// Process took longer than 15 minutes and timed out. Please complete the process again.

			fetch('https://nr4gpozfngwqavodtmdxpqgjg40xvizn.lambda-url.us-east-1.on.aws/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ auth_key: authKey, id: state.storeId }),
			})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to complete authorization.');
				}
				return response.json();
			})
			.then(() => {
				setMessage('Authorization completed successfully. The transactions will begin executing automatically when funds are cleared. You can now close this page.  ');

				const url = new URL(window.location.href);
				url.searchParams.set('success', 'true');
				window.history.replaceState(null, '', url.toString());
			})
			.catch((error) => {
				setErrorCode('ERROR_STORING_KEY');
				setMessage(`Error: ${error.message}`);

				const url = new URL(window.location.href);
				url.searchParams.set('state', '{}}');
				window.history.replaceState(null, '', url.toString());
			});
		} else {
			setErrorCode('MISSING_KEY');
			setMessage('Authorization key not found in the URL.');
		}
	}, []);

	return (
			<div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', textAlign: 'center' }}>
				<h1>Authorization Status</h1>
				{
					!message ? (
							<p>Loading...</p>
					) : null
				}
				{errorCode ? (
						<div style={{ color: 'red' }}>
							<p>{message}</p>
						</div>
				) : (
						<p>{message}</p>
				)}
			</div>
	);
};

export default AuthKeyHandler;
