import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import ProsperAuthPage from './components/ProsperAuthPage';
import ProsperCallbackPage from './components/ProsperCallbackPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51R8QlFEsh7voSkMMVto5SZWk6kUkTJd9WZucqBLRGzXVCxvPqzS1JIkwue813DVvDjXY1U69BjPYjGv4vkDy3nTA007pF0IDgz');

function App() {
  return (
      <Elements stripe={stripePromise} options={{wallets: {link: 'never',}}}>
          <Routes>
            <Route path="/prosper/callback" element={<ProsperCallbackPage />} />
            <Route path="/prosper/auth" element={<ProsperAuthPage />} />
            <Route path="/" element={<Home />} />
          </Routes>
      </Elements>
  );
}

export default App;
