import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const usStatesAndTerritories = [
	{ code: "AL", name: "Alabama" },
	{ code: "AK", name: "Alaska" },
	{ code: "AZ", name: "Arizona" },
	{ code: "AR", name: "Arkansas" },
	{ code: "CA", name: "California" },
	{ code: "CO", name: "Colorado" },
	{ code: "CT", name: "Connecticut" },
	{ code: "DE", name: "Delaware" },
	{ code: "DC", name: "District of Columbia" },
	{ code: "FL", name: "Florida" },
	{ code: "GA", name: "Georgia" },
	{ code: "HI", name: "Hawaii" },
	{ code: "ID", name: "Idaho" },
	{ code: "IL", name: "Illinois" },
	{ code: "IN", name: "Indiana" },
	{ code: "IA", name: "Iowa" },
	{ code: "KS", name: "Kansas" },
	{ code: "KY", name: "Kentucky" },
	{ code: "LA", name: "Louisiana" },
	{ code: "ME", name: "Maine" },
	{ code: "MD", name: "Maryland" },
	{ code: "MA", name: "Massachusetts" },
	{ code: "MI", name: "Michigan" },
	{ code: "MN", name: "Minnesota" },
	{ code: "MS", name: "Mississippi" },
	{ code: "MO", name: "Missouri" },
	{ code: "MT", name: "Montana" },
	{ code: "NE", name: "Nebraska" },
	{ code: "NV", name: "Nevada" },
	{ code: "NH", name: "New Hampshire" },
	{ code: "NJ", name: "New Jersey" },
	{ code: "NM", name: "New Mexico" },
	{ code: "NY", name: "New York" },
	{ code: "NC", name: "North Carolina" },
	{ code: "ND", name: "North Dakota" },
	{ code: "OH", name: "Ohio" },
	{ code: "OK", name: "Oklahoma" },
	{ code: "OR", name: "Oregon" },
	{ code: "PA", name: "Pennsylvania" },
	{ code: "RI", name: "Rhode Island" },
	{ code: "SC", name: "South Carolina" },
	{ code: "SD", name: "South Dakota" },
	{ code: "TN", name: "Tennessee" },
	{ code: "TX", name: "Texas" },
	{ code: "UT", name: "Utah" },
	{ code: "VT", name: "Vermont" },
	{ code: "VA", name: "Virginia" },
	{ code: "WA", name: "Washington" },
	{ code: "WV", name: "West Virginia" },
	{ code: "WI", name: "Wisconsin" },
	{ code: "WY", name: "Wyoming" },
	// Territories
	{ code: "AS", name: "American Samoa" },
	{ code: "GU", name: "Guam" },
	{ code: "MP", name: "Northern Mariana Islands" },
	{ code: "PR", name: "Puerto Rico" },
	{ code: "VI", name: "U.S. Virgin Islands" },
	// Military
	{ code: "AA", name: "Armed Forces Americas" },
	{ code: "AE", name: "Armed Forces Europe" },
	{ code: "AP", name: "Armed Forces Pacific" },
];


const ProsperAuthPage = () => {
	let [step, setStep] = React.useState(0);
	const [email, setEmail] = React.useState('');
	const [name, setName] = React.useState('');
	const [state, setState] = React.useState('');
	const [zip, setZip] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [storeId, setStoreId] = React.useState(false);
	const [storeTimestamp, setStoreTimestamp] = React.useState(false);
	const [cardComplete, setCardComplete] = React.useState(false);
	const stripe = useStripe();
	const elements = useElements();

	const handleCardChange = (event) => {
		setCardComplete(event.complete);
	};

	const handleEmailSubmit = async (event) => {
		event.preventDefault();
		// eslint-disable-next-line no-undef
		gtag('event', 'page_1_submit_clicked', {
			'event_category': 'form_interaction',
			'event_label': 'submit_button',
		});
		setLoading(true);
		setError(null);

		try {
			const response = await fetch('https://pf6qpsdx2w5uo75ogqhele6i7e0bjexc.lambda-url.us-east-1.on.aws/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email, name, country: 'US', state, zip }),
			});

			const data = await response.json();

			if (!response.ok) {
				throw new Error(data.error || 'Failed to create SetupIntent');
			}

			let clientSecret = data.clientSecret;
			let customerId = data.customerId;

			const result = await stripe.confirmCardSetup(clientSecret, {
				payment_method: {
					card: elements.getElement(CardElement),
					billing_details: {
						name,
					},
				},
			});

			if (result.error) {
				console.error(result.error.message);
			} else {
				console.log('Card saved successfully! PaymentMethod ID:', result.setupIntent.payment_method);

				const responseToStore = await fetch('https://rmzuzttpeswslnum5ekyjvway40qbvmw.lambda-url.us-east-1.on.aws/', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ email, name, customerId, paymentMethod: result.setupIntent.payment_method,}),
				});

				const {id, timestamp, error} = await responseToStore.json();
				if (!responseToStore.ok) {
					throw new Error(error || 'Failed to create SetupIntent');
				}
				setStoreId(id);
				setStoreTimestamp(timestamp);

				setStep(step + 1);

				// eslint-disable-next-line no-undef
				gtag('event', 'page_1_submit_success', {
					'event_category': 'form_interaction',
					'event_label': 'submit_button',
				});
			}
		} catch (err) {
			// eslint-disable-next-line no-undef
			gtag('event', 'page_1_submit_error', {
				'event_category': 'error',
				'event_label': 'submit_button',
				'value': err.message,
			});
			console.error(err);
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	const handleStripeSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const { error, paymentIntent } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/success`,
			},
		});

		if (error) {
			console.error(error);
		} else if (paymentIntent && paymentIntent.status === 'succeeded') {
			// Payment successful
		}
	};

	const inputBlur = async (event) => {
		// eslint-disable-next-line no-undef
		gtag('event', 'page_1_form_blur', {
			'event_category': 'form_interaction',
			'event_label': event.target.name,
		});
	}

	const handleAuthenticateClick = () => {
		// eslint-disable-next-line no-undef
		gtag('event', 'page_2_submit_clicked', {
			'event_category': 'form_interaction',
			'event_label': 'authenticate_button',
		});
		const query = new URLSearchParams({
			state: JSON.stringify({
				storeId,
				storeTimestamp,
			}),
			client_id: 'e4c074c37dbb4c7689039aa54da39916',
			response_type: 'auth_key',
		});

		let url = `https://www.prosper.com/oauth?${query.toString()}`;
		console.log('url', url);
		window.location.href = url;
	};

	if ( step === 0 ) {
		return (
				<div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', textAlign: 'center' }}>
					<h1>Triple V Onboarding</h1>
					<h2>Step 1: Enter Payment Method For Fees</h2>
					<form onSubmit={handleEmailSubmit} style={{display: 'flex', 'flexDirection': 'column',alignItems: 'center',}}>
						<label style={{margin: '6px 0',}}>
							Full Name:
							<input
									style={{minWidth: 200,}}
									type="text"
									placeholder="Enter your full name"
									value={name}
									onChange={e => setName(e.target.value)}
									required
									name="name"
									onBlur={inputBlur}
							/>
						</label>
						<label style={{margin: '6px 0',}}>
							Email Address:
							<input
									style={{minWidth: 200,}}
									type="email"
									placeholder="Enter your email"
									value={email}
									onChange={e => setEmail(e.target.value)}
									required
									name="email"
									onBlur={inputBlur}
							/>
						</label>
						<label style={{margin: '6px 0',}}>
							State:
							<select value={state} onChange={e => setState(e.target.value)} required style={{minWidth: 200,}} name="state" onBlur={inputBlur}>
								<option value="">Select state or territory</option>
								{usStatesAndTerritories.map((s) => (
										<option key={s.code} value={s.code}>
											{s.name}
										</option>
								))}
							</select>
						</label>
						<label style={{margin: '6px 0',}}>
							Zip Code:
							<input
									style={{minWidth: 100,}}
									type="zip"
									placeholder="Enter your zipcode"
									value={zip}
									onChange={e => setZip(e.target.value)}
									required
									name="zip"
									onBlur={inputBlur}
							/>
						</label>
						<div style={{margin: '6px 0',}}><label>Enter payment card info securely via Stripe:</label></div>
						<div style={{margin: '6px 0', height: 40, padding: '10px 12px', border: '1px solid #ccc', borderRadius: 4, display: 'block', minWidth: '320px',}}>
							<CardElement onChange={handleCardChange} options={{disableLink: true,}}/>
						</div>
						<button style={{margin: '6px 0',}} type="submit" disabled={loading || !cardComplete || !name || !email || email.indexOf('@') <= 0 || !state || !zip}>
							{loading ? 'Processing...' : 'Continue'}
						</button>
						{error && <div style={{ color: 'red' }}>{error}</div>}
					</form>
				</div>
		);
	} else if ( step === 1 ) {
		return (
				<div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', textAlign: 'center' }}>
					<h1>Triple V Onboarding</h1>
					<h2>Step 2: Authorize Triple V API to your Prosper Account</h2>
					<p>
						To connect your Prosper account, click the "Authenticate" button below. You will be taken to
						Prosper's authentication page where you need to:
					</p>
					<ol style={{ textAlign: 'left', display: 'inline-block' }}>
						<li>Click on "Write & Read Access".</li>
						<li>Enter your Prosper login credentials (email and password).</li>
						<li>Check the "I am not a robot" box.</li>
						<li>Click "Grant Access".</li>
						<li>You will then be redirected back to this page.</li>
					</ol>
					<div style={{width: '100%', margin: '32px 0 64px',}}>
						<button
								onClick={handleAuthenticateClick}
								style={{
									padding: '10px 20px',
									fontSize: '16px',
									backgroundColor: '#4CAF50',
									color: 'white',
									border: 'none',
									borderRadius: '5px',
									cursor: 'pointer'
								}}
						>
							Authenticate
						</button>
					</div>
					<div style={{ marginTop: '20px' }}>
						<h3>Reference Screenshot</h3>
						<img
								src="/prosper-screenshot-1.png"
								alt="Prosper Authentication Page Screenshot"
								style={{ maxWidth: '100%', border: '1px solid #ccc', borderRadius: '5px' }}
						/>
					</div>
				</div>
		);
	}
};

export default ProsperAuthPage;
