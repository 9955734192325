import React from 'react';

const Home = () => {

	return (
			<div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', textAlign: 'center' }}>
				<h1>Hartfield Technologies</h1>
				<p>
					Founded in 2016
				</p>
				<p>
					Please reach an admin for more info
				</p>
			</div>
	);
};

export default Home;
